import React from "react";
import s from './Accoint.module.scss'

export const Account = () => {
    return(
        <div>
            <h1>Account</h1>
        </div>
    )
}
