import React, {useState} from "react";
import styles from './LogIn.module.scss'
import axios from "axios";
import config from "../../config";
import {useNavigate} from 'react-router-dom'
import Input from '../../UI/Input/Input'
import useInput from "../../hooks/useInput";
import useValidation from '../../hooks/useValidation'
import EyeImage from '../../assets/icons/EyeImage.png';
import ClosedEye from '../../assets/icons/ClosedEye.png';
import TextField from '@mui/material/TextField'
import {styled} from '@mui/material/styles';

export const LogIn = (props) => {
    const navigate = useNavigate()
    const {isNotEmpty, isEmail} = useValidation();

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched: emailIsTouched,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse: showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isNotEmpty);


    let emailMessage = null;
    if (!email) {
        emailMessage = "Email is required";
    } else if (!emailIsValid) {
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if (emailIsValid
        && passwordIsValid
    ) {
        formIsValid = true;
    }


    const values = {
        email,
        password,
    }


    let postLogin = async (values) => {
        let formData = {
            email: values.email,
            password: values.password,
            device_name: 'qqq'
        }

        try {
            let response = await axios.post(`${config.baseUrl}sanctum/token`, formData);
            // sessionStorage.setItem('token', response.data.token);
            // props.setAccessToken(sessionStorage.getItem('token'));
            // sessionStorage.setItem('role', response.data.role);
            // props.setRole(sessionStorage.getItem('role'));
            console.log(response, 'resssss')
            if (response) {
                navigate('/invoice')
            }

            // if(response.data.role ==="superadmin"){
            //     navigate('/companies/created');
            // }
            // if(response.data.role ==="candidate"){
            //     navigate('/indicators');
            // }
        } catch (error) {
            console.log(error, 'errror')


        }
    }


    const submitHandler = event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        postLogin(values);
        resetEmail();
        resetPassword();
        showPassFalse()
    }
    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }
    ///////////////////////////////////////////
    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: '#373737',
            fontWeight: 400
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                width: '100%',
                border: '2px solid #B8B8B8',
            },
            borderRadius: 26,
            width: 500
        },

    });

    return (
        <div className={styles.sign_container}>

            <div className={styles.sign_content}>
                <div className={styles.sign_title}>
                    Get Your Chart
                </div>
                <div className={styles.sign_subtitle}>
                    The day you were born you were already living as your highest self. <br/>
                    We use your birth information to map out exactly who you came here to be
                </div>

                <form className={styles.sign_form} onSubmit={submitHandler}>
                    <div className={styles.sign_input_item}>
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        <CssTextField label="Name" id="custom-css-outlined-input"/>
                    </div>
                    <div className={styles.sign_input_item}>
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        <CssTextField label="Email" type={email} id="custom-css-outlined-input"/>
                    </div>


                    <div className={styles.sign_input_item}>
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        <CssTextField label="Location" id="custom-css-outlined-input"/>
                    </div>

                    <div className={styles.sign_subtitle_sub}>
                        In Kabbalah mapping the birth place is only used to obtain the correct timezone. If your city
                        does not appear in our database, please select the nearest larger city in the same timezone as
                        your own.
                    </div>

                    {/*<Input*/}
                    {/*    hasError={emailHasError}*/}
                    {/*    errorText={emailMessage}*/}
                    {/*    label='Email'*/}
                    {/*    input={{*/}
                    {/*        value: email,*/}
                    {/*        placeholder: "Email",*/}
                    {/*        type: "email",*/}
                    {/*        onChange: emailChangeHandler,*/}
                    {/*        onBlur: emailBlurHandler*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<Input*/}
                    {/*    hasError={passwordHasError}*/}
                    {/*    label='Password'*/}
                    {/*    errorText="Please enter valid password."*/}
                    {/*    onClick={passwordShowHandler}*/}
                    {/*    image={passwordShow ? EyeImage : ClosedEye}*/}
                    {/*    input={{*/}
                    {/*        width: "100%",*/}
                    {/*        value: password,*/}
                    {/*        placeholder: "Password",*/}
                    {/*        type: passwordShow ? "text" : "password",*/}
                    {/*        onChange: passwordChangeHandler,*/}
                    {/*        onBlur: passwordBlurHandler,*/}
                    {/*        onKeyPress: handleKeyPress*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<div className={s.sign_input_item}>*/}
                    {/*    <label htmlFor="email">Email</label>*/}
                    {/*    <input*/}
                    {/*        type="email"*/}
                    {/*        placeholder="Email"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputEmail1"*/}
                    {/*        aria-describedby="emailHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={s.sign_input_item}>*/}
                    {/*    <label htmlFor="password">Password</label>*/}
                    {/*    <input*/}
                    {/*        type="password"*/}
                    {/*        placeholder="Password"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputPassword"*/}
                    {/*        aria-describedby="passwordHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}


                    <div className={styles.sign_btn_container}>
                        <button className={styles.btn_outline} type={"submit"}>View your chart</button>
                    </div>
                </form>

            </div>
            {/*<div className={styles.sign_text}>This site is protected by <span className={styles.sign_text_link}>*/}
            {/*    reCAPTCHA</span> and the <span className={styles.sign_text_link}>Google Privacy Policy</span> and <span*/}
            {/*    className={styles.main_text_link}>Terms of Service</span> apply.*/}
            {/*</div>*/}

        </div>
    )
}
