import React from "react";
import styles from "./TopHeader.module.scss";
import {useNavigate} from "react-router-dom";
export const TopHeader = () => {
    const navigate=useNavigate();
    return(
        <div className={styles.top_bar}>
            <div className={styles.top_bar__logo} onClick={() => { navigate('/')}}>

            </div>
            <div className={styles.top_bar__menu_container}>
                <div className={styles.top_bar__menu_item}>Media</div>
                <div className={`dropdown ${styles.top_bar__dropdown}`}>
                    <button className="dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Work With Sheefra
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li className="dropdown-item" onClick={() => { navigate('/login')}}>Login</li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                </div>
                <div className={styles.top_bar__user_logo}></div>
                {/*<input type="text" placeholder={`Find your invoice`}/>*/}
            </div>
        </div>
    )
}
