import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {HomePage} from "./pages/HomePage/HomePage";
import {LogIn} from "./pages/LogIn/LogIn";
import {InvoicePage} from "./pages/InvoicePage/InvoicePage";
import Layout from "./components/Layout/Layout";
import {Account} from "./pages/account/Account";
import {CallCenter} from "./pages/CallCenter/CallCenter";
import {Settings} from "./pages/Settings/Settings";

function App() {
  return (
      <BrowserRouter>
          <Routes>

              {/*<Route path='/login' element={<LogIn/>}/>*/}
              {/*<Route path="/" element={<Navigate to="/home"/>}/>*/}

              <Route  path='/' element={<Layout />}>
                  <Route index path='/' element={<HomePage/>}/>
                  <Route  path='/login' element={<LogIn/>}/>
                  <Route  path='/invoice' element={<InvoicePage/>}/>
                  <Route path='/account' element={<Account />} />
                  <Route path='/call_center' element={<CallCenter />} />
                  <Route path='/settings' element={<Settings />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
