import React from "react";
import s from './Settings.module.scss'

export const Settings = () => {
    return (
        <div>
            <h1>Settings</h1>
        </div>
    )
}
