import React from "react";
import styles from './HomePage.module.scss'
import {useNavigate} from 'react-router-dom'

export const HomePage = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className={styles.main_container}>
                <div className={styles.main_img}></div>

                <div className={styles.main_content__container}>
                    <p className={styles.main_content__sub}>An entire lifetime could pass by while you focus on what you
                        “should” do…</p>
                    <h2 className={styles.main_content__title}>Are you ready to unlock your inner magic?</h2>
                    <p className={styles.main_content__italic_text}>For a 20 Minute Intro to the Four Worlds of Kabbalah
                        and my “Slow Down To Tap in” meditation, enter your email below.</p>
                    <button className={styles.main_content__btn}>Discover your design</button>
                </div>
            </div>

            <div className={styles.as_seen__container}>
                <h2 className={styles.as_seen__title}>As seen on</h2>
                <div className={styles.as_seen__boxes}>
                    <div className={`${styles.as_seen__boxes_item} ${styles.item_1}`}></div>
                    <div className={`${styles.as_seen__boxes_item} ${styles.item_2}`}></div>
                    <div className={`${styles.as_seen__boxes_item} ${styles.item_3}`}></div>
                    <div className={`${styles.as_seen__boxes_item} ${styles.item_4}`}></div>
                </div>
            </div>

            <div className={styles.send_me__container}>
                <div className={styles.send_me__background}></div>
                <div className={styles.send_me__content}>
                    <p className={styles.send_me__title}>**PLUS** receive the “10 Ways to Heal Through Kabbalah”
                        e-book</p>
                    <div className={styles.send_me__input_container}>
                        <input className={styles.send_me__input} type="email" placeholder='Email Address'/>
                        <button className={styles.send_me__btn}>Send it to me</button>
                    </div>
                </div>
            </div>

            <div className={styles.thank_you__container}>
                <div className={styles.thank_you__box}>
                    <p className={styles.thank_you__text}>“Sheefra, I wanted to say how amazing your calls have been.
                        You have helped me grow, learn, and
                        love the process, and when I breathe there is more joy and peace and less fear and tension. My
                        body, mind, and soul appreciate you. Thank you! <span
                            className={styles.thank_you__heart}></span></p>
                    <p className={styles.thank_you__owner_text}>- Cassi, Denver, Business Owner</p>
                </div>
            </div>

            <div className={styles.discover__container}>
                <div className={styles.discover__content}>
                    <div className={styles.discover__content_boxes}>
                        <div className={styles.discover__box}>
                            <div className={`${styles.discover__content__box1} ${styles.main_green}`}></div>
                            <div className={styles.discover__content__box1}>
                                <h4 className={styles.box__title}>RESTORE REVERANCE.</h4>
                                <p className={styles.box__description}>Through creating a sacred pause in our modern
                                    lives, we can connect with this magical
                                    realism. Only in this space can we listen to the whispers of guidance from our
                                    ancestors and deepen our connection to our calling. It’s by combining ancient
                                    kabbalistic teachings with traditional healing modalities that Four Worlds supports
                                    you on this journey of remembrance.</p>
                            </div>
                        </div>
                        <div className={styles.discover__box}>
                            <div className={`${styles.discover__content__box2} ${styles.main_forest}`}>
                                <h4 className={styles.box__title}>DISCOVER THE MYSTERY…</h4>
                                <p className={styles.box__description}>If you’re reading this, it’s time - there is
                                    magic inside of you waiting to be discovered. This magic has a pulse, and when it is
                                    unlocked, you are able to fully live out the purpose for which you were born.</p>
                            </div>
                            <div className={styles.discover__content__box2}>
                                <h4 className={styles.box__title}>YOUR STORY ISN’T OVER YET…</h4>
                                <p className={styles.box__description}>In every moment of every day, we are weaving the
                                    story that we are going to wrap
                                    ourselves in when we die. Our life is a collection of all the little narratives we
                                    tell ourselves. And no matter your past or your present, we have the power to
                                    alchemize these stories to become the author of our lives, and live our purpose</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.discover__content_img}></div>
                </div>
            </div>

            <div className={styles.training__container}>
                <h5 className={styles.training__title}>That why I created the SLOW DOWN TO TAP IN training series. So
                    you can rediscover the depths of who
                    you really are by applying ancient principles to your modern life.</h5>
                <div className={styles.send_me__input_container}>
                    <input className={styles.send_me__input} type="email" placeholder='Email Address'/>
                    <button className={styles.send_me__btn}>Send it to me</button>
                </div>
                <h5 className={styles.training__subtitle}>* BONUS - when you sin up, you will get an intro to kabbalah
                    and meditation video, and my 10 Ways to Heal Though Kabbalah ebook!</h5>
            </div>
            <div className={styles.map__container}>
                <div className={styles.map__container_bg}>
                    <div className={styles.map__header}>
                        <h3 className={styles.map__header_title}>THE MAP OF FOUR WORLDS</h3>
                    </div>
                    
                    <div className={styles.map__text_container}>
                        <div className={styles.map__text}>
                            1- Emanation
                            <span className={styles.map__text_sub}>(Belief)</span>
                        </div>
                        <div className={styles.map__text}>
                            2- Creation
                            <span className={styles.map__text_sub}>(Thought)</span>
                        </div>
                        <div className={styles.map__text}>
                            3- Formation
                            <span className={styles.map__text_sub}>(Speech/ Emotion)</span>
                        </div>
                        <div className={styles.map__text}>
                            4- Action
                            <span className={styles.map__text_sub}>(Action)</span>
                        </div>

                    </div>
                    <div className={styles.map__subtitle}>The Four Worlds act as a map of the four stages anything comes through to be created…</div>
                </div>


            </div>
        </div>
    )
}
