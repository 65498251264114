import React from "react";
import s from './InvoicePage.module.scss'

export const InvoicePage = () => {
    return (
        <div className={`${s.invoice_container} m-0`}>
            <div className={s.invoice_head}>
                <div className={`${s.invoice_head_box} ${s.dark}`}>
                    <span className={s.invoice_head_line}> </span>
                    <p className={s.invoice_head_text}>Unpaid Invoices</p>
                    <h3 className={s.invoice_head_price}>$ 1200.00</h3>
                    <p className={s.invoice_head_text}>From 1 invoice</p>
                </div>
                <div className={s.invoice_head_box}>
                    <p className={s.invoice_head_text}>History</p>
                    <h3 className={s.invoice_head_price}>4.386</h3>
                    <p className={s.invoice_head_text}>Your invoices</p>
                </div>
            </div>

            <div className={s.invoice_table_container}>
                <table className='table'>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Invoice references</th>
                        <th>Month</th>
                        <th>Due Date</th>
                        <th>Due Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td>NY-XYG-0976</td>
                        <td>12/10/2024</td>
                        <td>01/25/24</td>
                        <td>$ 1200.0</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}
