import React from "react";
import styles from "./Footer.module.scss";
export  const Footer = () => {
    return (
        <div className={styles.footer__container}>
            <div className={styles.footer__content}>
                <div className={styles.footer__logo}></div>
                <div className={styles.footer__media_container}>
                    <div className={styles.footer__media_content}>
                        <h4 className={styles.footer__media_title}>Media</h4>
                    </div>
                    <div className={styles.footer__media_content}>
                        <h4 className={styles.footer__media_title}>Work With Sheefra</h4>
                        <ul className={styles.footer__media_links}>
                            <li className={styles.footer__media_link}>1:1 Mentorship</li>
                            <li className={styles.footer__media_link}>Kabbalah Foundations Course</li>
                            <li className={styles.footer__media_link}>Kabbalah Map Reading</li>
                            <li className={styles.footer__media_link}>Inner Aliya Private Ritual Retreat</li>
                            <li className={styles.footer__media_link}>Book A Discovery Call</li>
                            <li className={styles.footer__media_link}>My Book + Card Deck</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footer__touch_container}>
                    <p className={styles.footer__touch_title}>Get In Touch</p>
                    <div className={styles.footer__touch_input_container}>
                        <input className={styles.footer__touch_input} type="email" placeholder='Email Address'/>
                        <button className={styles.footer__touch_btn}>Send it to me</button>
                    </div>
                    <div className={styles.footer__touch_follow}>Follow us on:
                        <a href="https://www.facebook.com" className={styles.footer__touch_facebook}></a>
                        <a href="https://www.instagram.com" className={styles.footer__touch_instagram}></a>
                    </div>
                </div>
            </div>
            <div className={styles.footer__copyright}>
                Copyright © 2024  Company S.L. All rights reserved.
            </div>
        </div>
    )
}
